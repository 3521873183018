.main-transition {
  &-initial {
    opacity: 1;
    background-color: lighten($color: #282c34, $amount: 6);
    width: 800px;
    height: 800px;
    transform: scale(4);
    border-radius: 50%;
    filter: blur(15px)
  }
  &-enter {
    transform: scale(0.01);
    &-active {
      transform: scale(4);
      transition: transform 600ms ease-in-out;
    }
  }
  &-exit {
    opacity: 1;
    transform: scale(4);
    &-active {
      opacity: 0;
      transition: opacity 500ms ease-in-out;
    }
  }
}
